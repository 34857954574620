<template>
  <div v-if="isShow" class="touch-cookies-statement">
    <div class="wrapper">
      <div class="description">
        {{ description }}
      </div>
      <div class="btn button btn-default" @click="handleAccept('Accept')">
        {{ $t('featureComponents.page.cookiesAccept.accept') }}
      </div>
    </div>
  </div>
</template>

<script>
// import commonPagesConfig from 'config/pages/common.js';

export default {
  data() {
    return {
      isShow: false,
      description: this.$t('featureComponents.page.cookiesStatement.description'),
    }
  },
  mounted() {
    // 初始默认可以使用本地cookies
    window.localStorage.setItem('cookiesAccept', 1);
    const cookiesAccept = window.localStorage.getItem('cookiesAccept', 1);
    if (!cookiesAccept) {
      this.isShow = true;
    }
  },
  methods: {
    handleAccept(btnName) {
      this.isShow = false;
      this.cookiesIsAcceptSa(btnName)
      window.localStorage.setItem('cookiesAccept', 1);
    },
    cookiesIsAcceptSa(btnName) {
      this.Sensors.track('bottomsheets_click', {
        button_content: btnName,
      })
    },
  },
};
</script>

<style lang="scss">
.touch-cookies-statement {
  position: fixed;
  bottom: 0;
  z-index: 99;
  width: 100%;
  height: px2vw(150);
  background: #f1f1f1;

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .description {
    width: px2vw(480);
    font-size: px2vw(24);
    line-height: px2vw(34);
    color: var(--txt-color-lv1);
  }

  .button {
    width: px2vw(190);
    height: px2vw(60);
    line-height: px2vw(60);
    border-radius: px2vw(30);
    font-size: px2vw(24);
  }
}
</style>
