/**
 * courses页面配置
 */
export default {
  /**
   * 新业务系统通用文案配置
   */
  gradeTitle: 'Grade',
  termTitle: 'Semester',
  lessonUnit: '節課',
  perLesson: '/課次',
  lessonUnitTxt: '{0}節課',
  leftTxt: '剩餘',
  lessonLeft: '{0}{1}節課',
  lessonStarted: '已開始{0}節課',
  courseEnded: '課程結束',
  suitableFor: '適用於',
  from: '起',
  noAddress: '暫無地址',
  classStore: '{store}人班',
  // -------------------
  gradeStageName: '',
  tutorText: 'Teacher',

  // 新分courses首页轮播图配置
  bannerList: [
    {
      src: 'https://download-pa-s3.thethinkacademy.com/static-pa/static/node_global_website_online/sg/touch/courses/01-master-teachers.jpg',
      url: '',
      target: '',
    },
    {
      src: 'https://download-pa-s3.thethinkacademy.com/static-pa/static/node_global_website_online/sg/touch/courses/02-best-tuition.jpg',
      url: '',
      target: '',
    },
    {
      src: 'https://download-pa-s3.thethinkacademy.com/static-pa/static/node_global_website_online/sg/touch/courses/03-service-primise.jpg',
      url: '',
      target: '',
    },
    {
      src: 'https://download-pa-s3.thethinkacademy.com/static-pa/static/node_global_website_online/sg/touch/courses/04-math-olympiad.jpg',
      url: '',
      target: '',
    },
  ],
  banner: {
    title: 'Think Academy Singapore',
    btnText: 'MORE',
    imgUrl: 'https://download-pa-s3.thethinkacademy.com/static-pa/static/node_global_website_online/sg/touch/courses/banner.jpg',
    linkUrl: '',
  },
  procedureTitle: '開始學習之旅的3個步驟',
  step: '第{index}步',
  FAQsTips: '如果您有任何問題，請查看我們的',
  procedureStep: [
    {
      icon: 'icon-desktop-outline',
      themeColor: '#ccc',
      title: '報名入學評估',
      des: '新生報班前必須參加入學評估測試，評估小朋友當前程度，老師會根據測試結果推薦適合報讀的班型。',
    },
    {
      icon: 'icon-access-course-details',
      themeColor: '#ccc',
      title: '報名參加課程',
      des: '選擇您感興趣的課程，並點擊“報名”購買。',
    },
    {
      icon: 'icon-attend-the-class',
      themeColor: '#ccc',
      title: '上課',
      des: '報名後，授課老師會透過電話聯絡您。 如您報的是線上課程，請<a href="/download" style="text-decoration: underline;color: #ffaa0a">下載Think Academy app</a>，上課前10分鐘進入教室，享受學而思學習之旅。',
    },
  ],
  procedureStep2: [
    {
      icon: 'icon-access-course-details',
      themeColor: '#ccc',
      title: '報名參加課程',
      des: '選擇您感興趣的課程，並點擊“報名”購買。',
    },
    {
      icon: 'icon-attend-the-class',
      themeColor: '#ccc',
      title: '上課',
      des: '報名後，授課老師會透過電話聯絡您。 如您報的是線上課程，請<a href="/download" style="text-decoration: underline;color: #ffaa0a">下載Think Academy app</a>，上課前10分鐘進入教室，享受學而思學習之旅。',
    },
  ],
  // 不同年级对应诊断地址
  gradeAssessmentMap: {
    0: 'https://ks.wjx.top/jq/89333319.aspx',
    1: 'https://ks.wjx.top/jq/89333707.aspx',
    2: 'https://ks.wjx.top/jq/89333539.aspx',
    3: 'https://ks.wjx.top/jq/89331911.aspx',
    4: 'https://ks.wjx.top/jq/89331895.aspx',
  },
  // 课程介绍，招生简章
  // 年级 => 招生简章图片地址
  courseInformation: {

  },
  coursesBanners: [
    { src: 'https://download-pa-s3.thethinkacademy.com/static-pa/static/node_global_website_online/uk/common/course-information/202008/Y1.jpg', isAssert: true },
    { src: 'https://download-pa-s3.thethinkacademy.com/static-pa/static/node_global_website_online/uk/common/course-information/202008/Y2.jpg', isAssert: false },
    { src: 'https://download-pa-s3.thethinkacademy.com/static-pa/static/node_global_website_online/uk/common/course-information/202008/Y3.jpg', isAssert: false },
  ],
  inclusive: 'GST',

  // 商城首页课程卡片文案配置
  courseCard: {
    levelDegreeMore: '多個級別供選擇',
    learnMore: '查看更多',
    lowPricePrefix: '',
    lowPriceTailfix: '起',
  },
  filter: {
    day: '星期',
    time: '上課時間',
    level: '等級',
    grade: '年級',
  },
  fillIinformation: {
    title: '如果沒有符合您要求的課程，請填寫您的具體需求：',
    form: {
      group: '年級',
      day: '星期',
      time: '上課時間',
      email: '郵箱',
      name: '姓名',
      agree: '訂閱我們的推送郵件，接收最新的諮詢和優惠政策！',
      submit: '提交',
    },
  },

}
