/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/**
 * Axios拦截器配置
 */
import Cookies from 'js-cookie';
import _$Dialog from 'vant/lib/dialog';
import bus from 'common/bus';
import { removeCookies } from 'common/utils';
import { getCustomToken } from '../libs/token'

/**
 * 获取当前渠道以及活动
 */
const getAttribution = (store) => {
  const sessionEventType = process.client ? window.sessionStorage.getItem('event_type') : ''
  const sessionEventId = process.client ? window.sessionStorage.getItem('event_id') : ''
  const sessionSourceType = process.client ? window.sessionStorage.getItem('source_type') : ''
  const sessionSourceId = process.client ? window.sessionStorage.getItem('source_id') : ''

  const { eventType } = store.state.attribution
  const { eventId } = store.state.attribution
  const { sourceType } = store.state.attribution
  const { sourceId } = store.state.attribution
  return `event_type=${sessionEventType || eventType}&event_id=${sessionEventId || eventId}&source_type=${sessionSourceType || sourceType}&source_id=${sessionSourceId || sourceId}`
}

export default function ({
  isDev, $axios, store, redirect, error, req, app,
}) {
  // 所有http状态码放行，防止服务端渲染时系统出错
  $axios.defaults.validateStatus = () => true

  // 处理接口发送
  $axios.onRequest(async (config) => {
    // 获取cookie
    let authToken = '';
    if (store.state.appDevice.toLowerCase() === 'ios' || store.state.appDevice.toLowerCase() === 'android') {
      authToken = (process.server) ? req.ctx.cookies.get('_app_token') || '' : Cookies.get('_app_token') || '';
    } else {
      authToken = (process.server) ? req.ctx.cookies.get('_official_token') || '' : Cookies.get('_official_token') || '';
    }
    // 是否发送Token
    const sendToken = config.sendToken !== undefined ? config.sendToken : true;
    // 超时时间20s
    config.timeout = 20000;
    // 编码方式
    config.headers['Content-Type'] = 'application/json;charset=UTF-8';
    // 统一参数处理
    config.headers.CountryCode = store.state.countryCode || '';
    config.headers.ChannelCode = store.state.channelCode || '';
    config.headers.platform = 'official';
    config.headers.Authorization = sendToken ? authToken : '';
    // 防刷机制参数
    config.headers['GRC-Action'] = store.state.verifyToken.action || '';
    // config.headers['GRC-Token'] = store.state.verifyToken.token || '';
    // 重置防刷参数
    store.commit('verifyToken/SEND_GRC_TOKEN', '');
    store.commit('verifyToken/SEND_GRC_ACTION', '');
    // 重写data字段
    if (config.rewritePostBody) {
      let origin = ''
      if (process.client) {
        const utmSource = sessionStorage.getItem('utm_source');
        const utmMedium = sessionStorage.getItem('utm_medium');
        const utmCampaign = sessionStorage.getItem('utm_campaign');
        origin = (utmSource && utmMedium && utmCampaign) ? `${utmSource}_${utmMedium}_${utmCampaign}` : '';
      }
      let attribution = getAttribution(store)
      if (config.data.sourceCode && config.data.mediaCode) {
        attribution += `&source_code=${config.data.sourceCode}&media_code=${config.data.mediaCode}`
      }
      if (config.leadsSubmitId) {
        attribution += `&leads_submit_id=${config.leadsSubmitId}`
      }
      const body = {
        data: config.data,
        header: {
          schoolCode: store.state.schoolCode,
          timezone: store.state.timezone,
          appName: store.state.appName || 'Official',
          appVersion: store.state.appVersion || '1.0.0',
          platform: store.state.appDevice || 'H5',
          platformVersion: '',
          origin,
          attribution,
        },
      }
      if (config.isMultiple || config.uid) {
        body.header.manipulated = config.isMultiple || config.uid
      }
      if (config.corpId) {
        body.header.corpId = config.corpId
      }
      // 防刷获取js验签
      const isNeedVerify = config.url.includes('v3/ucenter/common/verification') || config.url.includes('v3/ucenter/account/quick_login') || config.url.includes('crm/client/submitLeads') || config.url.includes('crm/client/qw/saveLeadsAction')
      if (isNeedVerify) {
        const env = process.env.runtime
        const preTimestampApi = 'https://one-pre.thethinkacademy.com/v1/system/timestamp'
        const betaTimestampApi = 'https://beta-one.thethinkacademy.com/v1/system/timestamp'
        const timestampApi = env === 'preprod' ? preTimestampApi : betaTimestampApi
        let signature = '';
        try {
          const data = {
            httpBody: body,
            timestampUrl: timestampApi,
          }
          if (env === 'prod') {
            delete data.timestampUrl
          }
          signature = await getCustomToken(data);
        } catch (err) {
          console.error(err);
        }
        config.headers['x-tal-signature'] = signature;
      }
      config.data = body;
      // 上传图片接口用的课中的比较特殊，传参结构需要改变; 还有客户服务相关接口
      if (config.url.includes('/one-upload-api/smartline/v1/getSTSToken') || config.url.includes('/customer/inner/')) {
        config.data = body.data
      }
    }
    // chatgpt接口重置超时时间
    if (config.rewriteTimeout) {
      config.timeout = config.rewriteTimer
    }
    return config;
  }, (err) => (Promise.reject(err)));

  // 处理接口返回结果
  $axios.onResponse((res) => {
    // const { $t } = $i18n
    if (!('data' in res) && !('code' in res.data) && !('msg' in res.data) && !('data' in res.data)) {
      error({ statusCode: res.status });
      return res;
    }

    // 业务返回数据
    const resData = res.data || {};
    const { code } = resData

    // 登录失效
    if (code === 9) {
      store.commit('ucenter/SET_IS_LOGIN', false);
      if (process.client) {
        // 清除Cookie
        removeCookies('_official_token')
        // APP客户端
        if (store.state.appDevice) {
          // 通知APP跳转登录页
          bus.$emit('app.hub.login');
        } else {
          // eslint-disable-next-line no-undef
          if ($nuxt.isOffline) return;
          _$Dialog.confirm({
            title: app.i18n.t('featureComponents.page.tips.login'),
            message: app.i18n.t('featureComponents.page.tips.loginTips'),
            confirmButtonText: app.i18n.t('featureComponents.page.tips.loginAgain'),
            cancelButtonText: app.i18n.t('featureComponents.page.tips.cancel'),
          }).then(() => {
            // on confirm
            if (window.location.pathname === '/bind-email') {
              window.location.href = `${window.location.origin}/login?${window.location.search}`;
            } else {
              window.location.href = `${window.location.origin}/login?successUrl=${window.location.pathname}${window.location.search}`;
            }
          }).catch(() => {
            // on cancel
            window.location.href = `${window.location.origin}/`
          });
        }
      }
    }

    // 业务异常上报
    // 0: 正常状态 9: 登录失效
    // if (![0, 9].includes(code)) {
    //   const { config = {} } = res
    //   $sentry.setTag('api', res.config.url)
    //   $sentry.setExtra('data', {
    //     status: res.status,
    //     statusText: res.statusText,
    //     method: config.method,
    //     requestHeader: config.headers,
    //     requestData: config.data,
    //     responseHeader: res.headers,
    //     responseData: res.data,
    //   })
    //   $sentry.captureMessage(`接口异常 ${config.url}`)
    // }

    // 业务接口无异常返回数据
    return resData;
  });

  // 处理接口报错
  $axios.onError((err) => {
    // chat机器人聊天特殊处理
    const curUrl = err.config?.url
    const urlList = ['/one-ai-api/webchat/reply', '/one-ai-api/webchat/ask']
    if (!isDev && !urlList.includes(curUrl)) {
      if (process.server) {
        redirect('/error');
      } else {
        window.location.href = '/error';
      }
    }
  });
}
