<template>
  <van-field ref="loginFormCaptchInput" v-model="code" class="captcha-input font-medium" name="captcha"
             :placeholder="$t('login.login.loginForm.captcha.placeholder')"
             maxlength="6" autocomplete="off" :rules="rules"
             @click="captchaInputClick()" @blur="captchaBlurHandler"
  >
    <template slot="extra">
      <div class="captcha-append-container font-medium">
        <template v-if="!sendLoading">
          <span v-if="startCountdown" class="resend-countdown">{{ resendCountdown }}S</span><span class="btn-send-captcha" :class="{'disabled': !isSendOtpBtnAvalible}" @click.stop="sendCodeHanlder">{{ sendOtpBtnText }}</span>
        </template>
        <template v-else>
          <van-loading class="send-loading" size="19px" />
        </template>
      </div>
    </template>
  </van-field>
</template>
<script>
import Cookies from 'js-cookie';
import { filterBlank } from 'common/utils.js';
import { oneApi } from 'config/api';
// import gRecaptcha from '@thinkacademy/vitas-utils/gRecaptcha';

export default {
  props: {
    scene: {
      type: Number,
      default: 1,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'phone',
    },
    phone: {
      type: [String, Number],
      default: '',
    },
    areaCode: {
      type: [String, Number],
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line
    beforeSend: Function,
    // eslint-disable-next-line
    sendError: Function,
    sendSuccess: {
      type: Function,
      default: () => {},
    },
    // eslint-disable-next-line
    cookieName: String,
    rules: {
      type: Array,
      default: () => [],
    },
    canClick: {
      type: Boolean,
      default: true,
    },
    studentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      startCountdown: false,
      sendOtpBtnText: this.$t('login.login.otpSendBtn.normal'),
      resendCountdown: 0,
      timer: null,
      code: '',
      sendLoading: false,
    }
  },
  computed: {
    isSendOtpBtnAvalible() {
      let canSend = false
      if (this.scene === 2) {
        canSend = (!this.startCountdown && !this.sendLoading);
      } else {
        canSend = (!this.startCountdown && this.canClick && !this.sendLoading);
      }
      return canSend
    },
  },
  watch: {
    value(val) {
      this.code = val
    },
    code(val) {
      this.$emit('input', val)
    },
  },
  methods: {
    init() {
      clearInterval(this.timer);
      this.startCountdown = false;
      this.sendOtpBtnText = this.$t('login.login.otpSendBtn.normal');
      this.resendCountdown = 0;
      this.timer = null;
      this.code = '';
      this.sendLoading = false;
    },
    sendCodeHanlder() {
      const sendFunc = () => {
        this.sendCaptcha()
      };
      if (!this.beforeSend) {
        sendFunc();
      } else if (typeof this.beforeSend === 'function') {
        this.beforeSend(sendFunc)
      }
    },
    /**
     * 发送手机验证码
     */
    async sendCaptcha() {
      if (!this.isSendOtpBtnAvalible) {
        return
      }
      this.sendLoading = true;
      this.resendCountdown = Cookies.get(this.cookieName) ? parseInt(Number(Cookies.get(this.cookieName)) - (new Date().getTime() / 1000), 10) : 0
      if (this.resendCountdown !== 0) {
        // 开始倒计时
        this.sendLoading = false;
        this.setIntervalFunc()
        this.sendSuccess();
        return
      }
      let data = {}
      if (this.type === 'phone') {
        data = {
          scene: this.scene,
          type: 0, // 0手机号
        }
        if (this.scene !== 2) {
          data.contactInfo = this.phone.trim()
          data.countryCallingCode = this.areaCode.trim()
        }
      } else {
        data = {
          scene: this.scene,
          type: 2, // 2邮箱
        }
        if (this.scene !== 2) {
          data.contactInfo = this.email.trim()
        }
      }
      // const { verifyKey } = process.env;
      // const token = await gRecaptcha.getToken.call(this, verifyKey)
      // this.$store.commit('verifyToken/SEND_GRC_TOKEN', token)
      this.$store.commit('verifyToken/SEND_GRC_ACTION', 'sendVerification')
      const res = await this.$axios.post(oneApi.sendLoginMobileCode, data, { rewritePostBody: true, uid: Number(this.studentId) || this.$store.state.ucenter.user.uid });
      this.sendLoading = false
      if (Number(res.code) !== 0) {
        this.isSending = false;
        this.$Toast(res.msg);
        if (typeof this.sendError === 'function') {
          this.sendError()
        }
        return;
      }
      this.sendSuccess();
      // 设置cookie倒计时数字
      this.resendCountdown = 60
      this.setCookie()
      // 开始倒计时
      this.setIntervalFunc()
      // this.setSendCodeBtn();
    },
    setCookie() {
      const seconds = new Date().getTime() / 1000 + 60;
      const expires = new Date(new Date() * 1 + 60 * 1000);
      Cookies.set(this.cookieName, seconds, { expires })
    },
    // 倒计时
    setIntervalFunc() {
      this.sendOtpBtnText = this.$t('login.login.otpSendBtn.reResend');
      this.startCountdown = true;
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.resendCountdown -= 1;
        if (this.resendCountdown === 0) {
          this.startCountdown = false;
          clearInterval(this.timer)
        }
      }, 1000)
    },
    captchaInputClick() {
      this.$refs.loginFormCaptchInput.focus();
    },
    // 验证码输入框失焦处理
    captchaBlurHandler() {
      this.value = filterBlank(this.value);
    },
  },
}
</script>
<style lang="scss" scoped>
.captcha-input {
  margin: 0;

  /deep/ .van-cell__value {
    width: px2vw(130);
    flex: 1;
  }

  .captcha-append-container {
    // flex: 1;
    margin-left: px2vw(28);
    margin-right: 0;
    width: fit-content;
    text-align: right;
    letter-spacing: px2vw(0.72);

    .resend-countdown {
      display: inline-block;
      margin-right: px2vw(20);
      width: px2vw(66);
      color: var(--txt-color-link);
    }

    .btn-send-captcha {
      text-align: right;
      color: var(--txt-color-link);

      &.disabled {
        color: rgba(23, 43, 77, 0.2);
      }
    }
  }
}
</style>
